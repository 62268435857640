<template>
  <div class="context-picker-modal">
    <b-modal
        id="modal-create-context"
        ref="modal-create-context"
        footer-class="flex-nowrap"
        size="lg"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createContext.title') }}</h3>
      </template>

      <template #default>
        <div class="m-4">
          <div>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.set') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div class="d-inline-block" v-if="personalizationSetData">
                  <b-form-select
                      v-model="personalizationSet"
                      :options="personalizationSetData">
                  </b-form-select>
                </div>
                <b-form-text>
                  {{ $t('modals.createContext.setDesc') }}
                </b-form-text>
              </b-col>
            </b-row>
          </div>
          <div v-if="personalizationSet">
            <b-row class="mb-4">
              <b-col cols="4"></b-col>
              <b-col cols="8">
                <b-form-checkbox
                  id="usePersonalizationSetAsContext"
                  v-model="usePersonalizationSetAsContext"
                  name="usePersonalizationSetAsContext"
                  :value="true"
                  :unchecked-value="false"
                >
                  {{ $t('modals.createContext.usePersonalizationSetAsContext') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-4" v-if="!usePersonalizationSetAsContext">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.value') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div class="mt-3">
                  <b-form-select
                      v-model="leftValue"
                      :options="personalizationFieldValues">
                  </b-form-select>
                  <b-form-text>
                    {{ $t('modals.createContext.valueDesc') }}
                  </b-form-text>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.operator') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div class="d-inline-block">
                  <b-form-select
                      v-model="operator"
                      :options="operatorOptions">
                  </b-form-select>
                </div>
                <b-form-text>
                  {{ $t('modals.createContext.operatorDesc') }}
                </b-form-text>
              </b-col>
            </b-row>
            <b-row v-if="!usePersonalizationSetAsContext">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.rightValue') }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-input
                    id="entry-name"
                    v-model="rightValue"
                    debounce="500"
                    @keyup.enter="createEntry"
                >
                </b-form-input>
                <b-form-text>
                  {{ $t('modals.createContext.rightValueDesc') }}
                </b-form-text>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="$emit('cancel-modal')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            :disabled="disableCreateButton"
            @click="createEntry"
        >
          {{ $t('buttons.create') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalCreateNewContext',
  props: {
    personalizationSetData: {
      type: Array,
    },
    personalizationValues: {
      type: Array,
    },
    action: {
      type: String,
    },
    orIndex: {
      type: Number,
    },
  },
  data() {
    return {
      personalizationSet: null,
      leftValue: null,
      operator: null,
      operatorOptions: [
        { value: null, text: this.$t('settings.page.context.condition.operatorOptions.operator') },
        { value: '==', text: this.$t('settings.page.context.condition.operatorOptions.isEqual') },
        { value: '!=', text: this.$t('settings.page.context.condition.operatorOptions.notEqual') },
      ],
      rightValue: null,
      usePersonalizationSetAsContext: false,
    };
  },
  mounted() {
    this.$refs['modal-create-context'].show();
  },
  computed: {
    disableCreateButton() {
      if (this.usePersonalizationSetAsContext && this.operator !== null) {
        return false;
      }
      return !this.leftValue || !this.operator || !this.rightValue;
    },
    personalizationFieldValues() {
      if (this.personalizationSet) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.leftValue = null;
        const { personalizationSet, personalizationValues } = this;
        const personalizationSetObject = personalizationValues
          .find((personalization) => personalization.setId === personalizationSet);
        if (personalizationSetObject) {
          if (!personalizationSetObject.values) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.personalizationSet = null;
          }
          return [
            { value: null, text: this.$t('settings.page.context.selectPersonalizationValue') },
            ...personalizationSetObject.values.map((field) => ({
              value: field.id,
              text: field.name,
            })),
          ];
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.personalizationSet = null;
      return [{ value: null, text: this.$t('settings.page.context.selectPersonalizationValue') }];
    },
  },
  methods: {
    createEntry() {
      if (this.usePersonalizationSetAsContext) {
        console.log('usePersonalizationSetAsContext', this.personalizationSet);
        const returnObject = {
          action: this.action,
          orIndex: this.orIndex,
          leftValue: 'personalized_variable_set_id',
          operator: this.operator,
          rightValue: this.personalizationSet,
        };
        this.$emit('create-context', returnObject);
        return;
      }
      if (this.leftValue && this.operator && this.rightValue) {
        const returnObject = {
          action: this.action,
          orIndex: this.orIndex,
          leftValue: `${this.personalizationSet}.${this.leftValue}`,
          operator: this.operator,
          rightValue: this.rightValue,
        };
        this.$emit('create-context', returnObject);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .modal-dialog {
  .modal-content {
    border: none;
  }

  .custom-control-input[disabled] ~ .custom-control-label {
    cursor: not-allowed;
  }
  button:disabled {
    cursor: not-allowed;
  }
}
</style>
